// /--------------------------------------
// /--------------------------------------
// /--------------------------------------
const winHeigt = window.innerHeight;

(function(){
  $(function(){

    $('a[href^="#"]').click(function() {
      let speed = 2000;
      let href= $(this).attr("href");
      let target = $(href == "#" || href == "" ? 'html' : href);
      let position;

      if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
    	// スマホ・タブレット（iOS・Android）の場合の処理を記述
        position = target.offset().top + -40;
        hamMenu.classList.remove("hamShow");
      }else{
      	// PCの場合の処理を記述
        position = target.offset().top + -90;
      }

      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });


    let circlePos = $(".circle");
    let outlineBtm = $("#buttom");
    // /--------------------------------------
    let scrollPos = $(".section_pos");
    let scrollPosArr = [];
    // let scrollPosLength = (scrollPos.length - 1 );
    let scrollPosLength = (scrollPos.length);


    window.addEventListener( 'load', function(){
      for ( let j = 0; j < circlePos.length; j++ ) {
        circlePos.on( "click", function(){
          circlePos.removeClass("posNow");
          $(this).addClass("posNow");
        });
      }

      for ( let i = 0; i < scrollPosLength; i++ ) {
        scrollPosArr.push( scrollPos.eq(i).offset().top );
      }
      // scrollPosArr.shift();
      // console.log( scrollPosArr );
    });



    window.addEventListener('scroll', function(){
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // let hitline = ( winHeigt + scrollTop - 300 );
      let hitline = ( winHeigt + scrollTop - 400 );

      for ( let k = 0; k < scrollPosLength; k++ ) {
        if ( hitline > scrollPosArr[k] ) {
          circlePos.removeClass("posNow");
          circlePos.eq(k).addClass("posNow");
        }
      }

      if ( hitline > scrollPosArr[1] ) {
        outlineBtm.addClass("hide");
      } else {
        outlineBtm.removeClass("hide");
      }
    });



  });
})();
